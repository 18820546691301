import React, { useState, useEffect } from 'react';
// import cn from 'classnames';

import IngredientsDropDown from '../IngredientsSelect/IngredientsDropDown';
import ImageInput from '../ImageInput/ImageInput';
import styles from './DishForm.module.css';

const DishImage = (props) => {
  const { 
    initialValues, 
    onChange,
    ingredientsData,
  } = props;

  const [image, setImage] = useState(initialValues?.image || '');
  const [ingredientId, setIngredientId] = useState(initialValues?.ingredient_id || ingredientsData[0]?.id);

  useEffect(() => {
    onChange({
      image, 
      ingredient_id: ingredientId,
      id: initialValues.id,
    });
  }, [image, ingredientId]);

  useEffect(() => {
    if (!image && ingredientsData.length) {
      setIngredientId(ingredientsData[0]?.id);
    }
  }, [ingredientsData]);

  const changeIngredientHandler = ({ target }) => {
    setIngredientId(target.value);
  };

  return (
    <div className={styles.dishImageBlock}>
      <ImageInput 
        value={image}
        onChange={setImage}
        name={initialValues.id}
      />
      <div className={styles.forIngredient}>For main ingredient:</div>
      <IngredientsDropDown 
        ingredientsData={ingredientsData}
        onChange={changeIngredientHandler}
        value={ingredientId}
      />
    </div>
  );
};

export default DishImage;
